import React from "react";
import { textShortDescr1, textShortDescr2, textShortDescr3 } from "../../Text";
import "./Intro.css";

const Intro = props => {
  return (
    <section id="intro">
      <span id="intro-section"></span>
      <img src="/images/profilepic.jpg" alt="image_not_found" />
      <div className="right-text">
        <h1>
          <span style={{ color: "var(--color-blue)" }}>Andrei Gramescu</span>
          ,&nbsp;
          <span>computing enthusiast</span>
        </h1>
        <ul className="list-titles">
          <li>
            <i className="fas fa-graduation-cap"></i>
            {textShortDescr1}
          </li>
          <li>
            <i className="fas fa-laptop"></i>
            {textShortDescr2}
          </li>
          <li>
            <i className="fas fa-globe-europe"></i>
            {textShortDescr3}
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Intro;
