import React from "react";
import "./CPres.css";

const CPres = ({ title, url, linkText, type, description, isDev }) => {
  return (
    <div className="cproject-pres pres-box">
      <div className="texts">
        <div className="title-container">
          <h4>{title}</h4>
          <p className="type">
            {type}{" "}
            {isDev && (
              <span>
                <br />{" "}
                <span style={{ fontSize: "0.9em", position: "absolute" }}>
                  (under development)
                </span>
              </span>
            )}
          </p>
        </div>
        <a
          href={url}
          target={url === "#" ? null : "_blank"}
          rel={url === "#" ? null : "noopener noreferrer"}
        >
          {linkText}
        </a>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

export default CPres;
