import React from "react";

// GLOBAL
export const urlGitHub = "https://github.com/andreigramescu";
export const urlLinkedIn = "https://www.linkedin.com/in/andreigramescu";

// INTRO
export const textShortDescr1 = "3nd Year at Imperial College";
export const textShortDescr2 = "Department of Computing";
export const textShortDescr3 = "London, UK";

// ACADEMICS
export const textAcademics1 =
  "My profesional journey starts in South Kensington, at Imperial College.\
  This is the place where I have the privilege\
  of pursuing a Masters in Computer Science and am now in the penultimate year\
  of my degree. Studying here\
  placed me in a highly demanding environment, where my discipline\
  and resilience are tested to their limits, and where I never stop learning\
  from a network of skilled peers. I have finished both years with solid\
  firsts and will undoubtedly continue studying hard in the next years to come,\
  as my passion for what I do continues to grow.";

export const textAcademics2 =
  "I also enjoy working as a Lab Teaching Assistant and a Personal Programming\
  Tutor within my department. Both involve helping younger students\
  with the programming module and the weekly coursework. The former\
  is conducted during lab sessions and is usually concerned with shorter\
  questions. The latter involves marking weekly coursework and committing to\
  performing one hour a week of teaching to a dedicated group of 8 first years.\
  Both jobs allow me to fine tune my communication skills and constantly train\
  the way I explain code to other people, especially newcomers.";

export const textModules =
  "With very few exceptions, I can confidently say\
  that I've enjoyed all the modules so far. I've experienced the benefits that\
  breadth of knowledge gave me both during university projects and the summer\
  internship. Having said that, there are still some module that I found\
  especially appealing in both years of study:";
export const textFavModules1 = [
  "Computing Practical (Haskell, Java and C)",
  "Discrete Mathematics",
  "Databases",
  "Computer Architecture",
  "Logic",
];
export const textFavModules2 = [
  "Operating Systems",
  "Computational Techniques",
  "Algorithm Design and Analysis",
  "Software Engineering Design",
  "Networks and Communications",
];

// Work & Projecs
export const textFrontEndHead = "Clean, elegant and fast Front-ends";
export const textFrontEndBody =
  "that are responsive, easy to use and navigate and as accessible as possible\
  to everyone on the web";
export const textBackEndHead = "Robust, restful APIs and Back-ends";
export const textBackEndBody =
  "with authentication, authorization, file-uploads, sending emails and CRUD\
  operations with databases";
export const textWebDevProjects = [
  {
    title: "iQUA Website",
    url: "https://www.iqua.ro",
    linkText: "www.iqua.ro",
    type: "Freelance work",
    img: "/images/iqua-pres.png",
  },
  {
    title: "Lab Scheduling Platform",
    url: "#",
    linkText: "Not allowed to share",
    type: "University coursework",
    img: "/images/ta-management-pres.png",
  },
  {
    title: "This Website",
    url: "https://www.andreigramescu.com",
    linkText: "www.andreigramescu.com",
    type: "Personal project",
    img: "/images/portfolio-pres.png",
  },
];
export const textScalaProjects = [
  {
    title: "WACC Compiler",
    url: "https://wacc.radzi.uk/",
    linkText: "Try it out online",
    type: "University coursework",
    description:
      "WACC is a programming language for which we had to write a full\
      compiler (with target ARM11) as part of the Second Year second term\
      group project. The first two tasks involved writing a robust front-end\
      (featuring parsing and semantic analysis as well as producing useful\
      and expressive error messages) and a back-end for assembly generation\
      that could also handle IO operations, stack / heap allocation and WACC\
      runtime errors. The last task of the project was to\
      extend the fairly limited language (unexpandable set of types, capable\
      of only primitives, pairs and arrays, no garbage collection or support\
      for functional programming patterns, to name a few) and add our own spin\
      to it. My team and I settled on higher-order functions, lambda syntax\
      , match statements and custom typing: the Unit type (semantics\
      very similar to Haskell's Void), aliases, sum types, function types and\
      recursive types.",
    isDev: false,
  },
];
export const textCProjects = [
  {
    title: "The Pintos Operating System",
    url: "#",
    linkText: "Not allowed to share",
    type: "University coursework",
    description:
      "An operating system built as part of the Second Year first term group\
      project, touching on many advanced concepts. We implemented a scheduling\
      system for Pintos using two methods: priority donations and a multi\
      level feedback queue. The Userprog task required implementing the process\
      arg and stack setup, a good deal of system calls, including wait and exec\
      , pagefaults and pointer management. Finally, implementing virtual memory\
      involved paging, lazy loading, moving to swap, an efficient eviction\
      algorithm, and solving an uncountable amount of deadlocks, memory leaks\
      and race conditions.",
    isDev: false,
  },
  {
    title: "GoForth",
    url: "https://github.com/andreigramescu/GoForth",
    linkText: "GitHub repo",
    type: "Personal project",
    description: (
      <span>
        A Forth interpreter and runtime environment built in collaboration with{" "}
        <a
          href="https://www.doc.ic.ac.uk/~lg319/LiamGallagher.html"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline" }}
        >
          Liam
        </a>
        . Forth is a fun, stack-oriented language used in embedded systems.
      </span>
    ),
    isDev: false,
  },
  {
    title: "Mandelbrot Set Renderer",
    url: "https://github.com/egroge/c_music_fractal",
    linkText: "GitHub repo",
    type: "University coursework",
    description: (
      <span>
        A Mandelbrot Set Renderer that enables the user to zoom in its infinite
        complexity. The calculations are done on the GPU using the OpenGL API.
        <a
          href="https://www.youtube.com/watch?v=AUMLG202PqI&ab_channel=\
          LiamGallagher"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline" }}
        >
          {" "}
          See video
        </a>
        .
      </span>
    ),
    isDev: false,
  },
  {
    title: "ARM11 Assembler and Emulator",
    url: "#",
    linkText: "Not allowed to share",
    type: "University coursework",
    description:
      "As part of the 3rd Term coursework, we made an emulator supporting a\
      subset of an ARM11 processor’s instructions as well as an assembler for\
      the same computer architecture.",
    isDev: false,
  },
];

// FOOTER
export const TextDataPrivacy = () => {
  return (
    <span>
      The data submitted in the contact form is not shared with any other third
      party. I use it for contact purposes{" "}
      <span style={{ fontWeight: "bold" }}>only</span>.
      <br />I do not use cookies to find more about you. The only cookies in
      place are from the Google capcha form.
    </span>
  );
};

export const textFlaticonMonkikLink = "https://www.flaticon.com/authors/monkik";
export const textFlaticonFreepikLink =
  "https://www.flaticon.com/authors/freepik";
export const textFlaticonLink = "https://www.flaticon.com/";
