import React from "react";
import {
  textAcademics1,
  textAcademics2,
  textFavModules2,
  textFavModules1,
  textModules,
} from "../../Text";
import "./Academics.css";

const Academics = props => {
  return (
    <section id="academics">
      <span id="academics-section"></span>
      <div className="wrapper">
        <h2>Academics</h2>
        <div className="text-photo-container">
          <div className="left-text">
            <p>{textAcademics1}</p>
          </div>
          <img src="/images/icl.jpeg" alt="image_not_found" />
        </div>
        <p>{textAcademics2}</p>
        <p>{textModules}</p>
        <div className="lists-wrapper">
          <ul className="first-year-list">
            {textFavModules1.map((module, index) => (
              <li key={index}>
                <i className="fas fa-check fa-xs"></i>
                {module}
              </li>
            ))}
          </ul>
          <ul className="second-year-list">
            {textFavModules2.map((module, index) => (
              <li key={index}>
                <i className="fas fa-check fa-xs"></i>
                {module}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="phone-gradient" />
    </section>
  );
};

export default Academics;
