import React from "react";
import "./IconLink.css";

const IconLink = ({ icon, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="icon-link"
    >
      <i className={icon}></i>
    </a>
  );
};

export default IconLink;
