import React from "react";
import BackEndSvg from "./back-end.svg";
import FrontEndSvg from "./front-end.svg";

import "./WorkNProjects.css";
import "./StarLeaf.css";
import StarLeaf from "./StarLeaf";
import WebsitePres from "./WebsitePres/WebsitePres";
import CPres from "./CPres/CPres";

import {
  textBackEndBody,
  textBackEndHead,
  textScalaProjects,
  textCProjects,
  textFrontEndBody,
  textFrontEndHead,
  textWebDevProjects,
} from "../../Text";

const WorkNProjects = props => {
  return (
    <section id="work-and-projects">
      <span id="work-and-projects-section"></span>
      <div className="wrapper">
        <StarLeaf />
        <br />
        <h2>Scala</h2>
        <ScalaProjects />
        <h2>C</h2>
        <CProjects />
        <br />
        <div className="top-container">
          <h2>
            Web development
            <br /> and design
          </h2>
          {/* <div className="quick-link-freelance">
            <i className="fas fa-user-secret fa-2x"></i>
            <span>
              Need someone to build you a solid website?
              <a href="#contact-section">Contact me</a>
            </span>
          </div> */}
        </div>

        <p style={{ paddingBottom: "20px" }}>In the past, I've built both:</p>
        <BoxesContainer />
        <p style={{ paddingBottom: "20px" }}>
          The last web project that I worked on was part of a university module:
          designing for real people. This team project was heavily focused on
          our ability to interact with potential users of our app. I got to
          practice the way I approach the entire process of building a web
          service: understanding the problem, prototyping with meaningful
          mockups, user stories, thin slicing and applying useful metrics.
        </p>

        <WebDevProjects />
        <br />
        <br />
      </div>
    </section>
  );
};

const BoxesContainer = () => (
  <div className="boxes-container">
    <div className="web-dev-box">
      <img src={FrontEndSvg} alt="image_not_found" />

      <h3>{textFrontEndHead}</h3>
      <p>{textFrontEndBody}</p>
      <ul>
        <li>
          <i className="fa-lg fab fa-js"></i>
        </li>
        <li>
          <i className="fa-lg fab fa-react"></i>
        </li>
        <li>
          <i className="fa-lg fab fa-css3-alt"></i>
        </li>
      </ul>
    </div>

    <div className="web-dev-box">
      <img src={BackEndSvg} alt="image_not_found" />
      <h3>{textBackEndHead}</h3>
      <p>{textBackEndBody}</p>
      <ul>
        <li>
          <i className="fa-lg fab fa-js"></i>
        </li>
        <li>
          <i className="fa-lg fab fa-node"></i>
        </li>
        <li>
          <i className="fa-lg fas fa-database"></i>
        </li>
      </ul>
    </div>
  </div>
);

const WebDevProjects = () => (
  <div className="slider">
    {textWebDevProjects.map(({ title, url, linkText, type, img, isDev }) => (
      <WebsitePres
        title={title}
        url={url}
        linkText={linkText}
        type={type}
        img={img}
        isDev={isDev}
      />
    ))}
  </div>
);

const ScalaProjects = () => (
  <div className="scalaprojects-container">
    {textScalaProjects.map(
      ({ title, url, linkText, type, description, isDev }) => (
        <CPres
          title={title}
          url={url}
          linkText={linkText}
          type={type}
          description={description}
          isDev={isDev}
        />
      )
    )}
  </div>
);

const CProjects = () => (
  <div className="cprojects-container">
    {textCProjects.map(({ title, url, linkText, type, description, isDev }) => (
      <CPres
        title={title}
        url={url}
        linkText={linkText}
        type={type}
        description={description}
        isDev={isDev}
      />
    ))}
  </div>
);

export default WorkNProjects;
