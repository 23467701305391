import React, { useState } from "react";
import IconLink from "../Subs/IconLink/IconLink";
import {
  TextDataPrivacy,
  textFlaticonFreepikLink,
  textFlaticonLink,
  textFlaticonMonkikLink,
  urlGitHub,
  urlLinkedIn,
} from "../../Text";
import "./Footer.css";

const Footer = props => {
  const [showDataPrivacy, setShowDataPrivacy] = useState(false);
  return (
    <footer>
      <div className="wrapper">
        <ul>
          <li>
            <span>© Andrei Gramescu. All rights reserved.</span>
            <span className="icons-list">
              <IconLink url={urlGitHub} icon="fab fa-github-square fa-lg" />
              <IconLink url={urlLinkedIn} icon="fab fa-linkedin fa-lg" />
            </span>
          </li>
          <li>
            Credits to{" "}
            <FooterTextLink url={textFlaticonMonkikLink}>monkik</FooterTextLink>{" "}
            and{" "}
            <FooterTextLink url={textFlaticonFreepikLink}>
              freepik
            </FooterTextLink>{" "}
            for the free large icons.
            <FooterTextLink url={textFlaticonLink}>flaticon.com</FooterTextLink>
          </li>
          <li>
            <span
              onClick={() => setShowDataPrivacy(prev => !prev)}
              className="data-priv-link"
            >
              Data privacy
            </span>
            {showDataPrivacy && (
              <div>
                <TextDataPrivacy />
              </div>
            )}
          </li>
        </ul>
      </div>
    </footer>
  );
};

const FooterTextLink = props => {
  return (
    <a
      href={props.url}
      target="_blank"
      rel="noopener noreferrer"
      className="footer-text-link"
    >
      {props.children}
    </a>
  );
};

export default Footer;
