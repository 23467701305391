import React from "react";
import IconLink from "../Subs/IconLink/IconLink";
import { urlGitHub, urlLinkedIn } from "../../Text";
import "./Nav.css";

const Nav = props => {
  return (
    <header id="header">
      <div className="wrapper">
        <div className="left">
          <a href="#intro-section" id="logo">
            <span style={{ color: "var(--color-blue)" }}>A</span>G
          </a>

          <nav>
            <a href="#academics-section">Academics</a>
            <a href="#work-and-projects-section">Work & Projects</a>
            <a href="#contact-section">Get in touch</a>
          </nav>
        </div>
        <div className="icons-list">
          <IconLink icon="fab fa-github-square fa-2x" url={urlGitHub} />
          <IconLink icon="fab fa-linkedin fa-2x" url={urlLinkedIn} />
          <span style={{
            marginLeft: "10px",
          }}>Last updated:
            <span style={{color: "var(--color-turquoise)"}}>
              {" 09-2021"}
            </span>
          </span>
        </div>
      </div>
    </header>
  );
};

export default Nav;
