import React, { useState } from "react";
import "./Contact.css";

const Contact = props => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [log, setLog] = useState(null);

  const handleMailChange = event => {
    setEmail(event.target.value);
  };
  const handleNameChange = event => {
    setName(event.target.value);
  };
  const handleMessageChange = event => {
    setMessage(event.target.value);
  };

  const handleSubmit = event => {
    setLog({ sending: true });
    if (!window.grecaptcha.getResponse()) {
      setLog({ error: "Are you a robot?" });
      return;
    }

    const url = "https://ag-website-backend.herokuapp.com/contact";
    const data = {
      email,
      name,
      message,
    };

    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
      .then(response => {
        console.log(response.status);
        if (response.status === 200) {
          setLog({
            success: `Thank you for your message${!name ? "" : ", " + name}.`,
          });
          setEmail("");
          setName("");
          setMessage("");
          return;
        } else if (response.status === 500) {
          setLog({
            error: "I am sorry, there is something wrong with my server.",
          });
          setEmail("");
          setName("");
          setMessage("");
          return;
        }
        console.log(response);
        response.json().then(data => {
          if (data.error) {
            setLog({ error: data.error });
          }
        });
      })
      .catch(error => {
        console.log(error);
        setLog({ error: "Something went wrong with yur request." });
        setEmail("");
        setName("");
        setMessage("");
      });
  };

  let logHead, logStr;
  if (!log) {
    logHead = null;
    logStr = null;
  } else if (log.sending) {
    logHead = <SendingMessage />;
    logStr = "";
  } else if (log.error) {
    logHead = <ErrorMessage />;
    logStr = log.error;
  } else {
    logHead = <SuccessMessage />;
    logStr = log.success;
  }

  return (
    <section id="contact">
      <span id="contact-section"></span>
      <div className="mail-form wrapper">
        <h2>Contact</h2>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="field-wrapper required">
            <p>email</p>
            <div>
              <input type="text" onChange={handleMailChange} value={email} />
              <i className="far fa-envelope"></i>
            </div>
          </div>
          <div className="field-wrapper">
            <p>name</p>
            <div>
              <input type="text" onChange={handleNameChange} value={name} />
              <i className="fas fa-signature"></i>
            </div>
          </div>
        </div>
        <div className="field-wrapper required message">
          <p>message</p>
          <div>
            <textarea onChange={handleMessageChange} value={message}></textarea>
            <i className="far fa-paper-plane"></i>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "55px",
          }}
        >
          <div
            className="g-recaptcha"
            data-sitekey="6LcCU9MZAAAAAGTFC9ifpG0QC8hseVc8agSn-Fiw"
          ></div>
          <button className="bttn-submit" id="bttn-send" onClick={handleSubmit}>
            SEND <i className="far fa-paper-plane"></i>
          </button>
        </div>
        <div className="request-log">
          {logHead} {logStr}
        </div>
      </div>
    </section>
  );
};

const ErrorMessage = props => {
  return <span style={{ color: "#e85c5a", fontWeight: "bold" }}>Error:</span>;
};

const SuccessMessage = props => {
  return <span style={{ color: "#5ae86b", fontWeight: "bold" }}>Success:</span>;
};

const SendingMessage = props => {
  return <span style={{ color: "var(--color-faded-grey)" }}>Sending...</span>;
};

export default Contact;
