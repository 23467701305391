import React from "react";
import Nav from "./components/Nav/Nav";
import Intro from "./components/Intro/Intro";
import Academics from "./components/Academics/Academics";
import WorkNProjects from "./components/WorkNProjects/WorkNProjects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Nav />
      <Intro />
      <Academics />
      <WorkNProjects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
