import React from "react";
import "./WebsitePres.css";

const WebsitePres = ({ url, linkText, title, type, isDev, img }) => {
  return (
    <div className="website-pres pres-box">
      <div className="texts">
        <h4>{title}</h4>
        <a
          href={url}
          target={url === "#" ? null : "_blank"}
          rel={url === "#" ? null : "noopener noreferrer"}
        >
          {linkText}
        </a>
        <p className="type">
          {type}{" "}
          {isDev && (
            <span>
              <br />{" "}
              <span style={{ fontSize: "0.85em", position: "absolute" }}>
                (under development)
              </span>
            </span>
          )}
        </p>
      </div>
      <img src={img} alt="image_not_found" />
    </div>
  );
};

export default WebsitePres;
