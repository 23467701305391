import React from "react";

const StarLeaf = props => {
  return (
    <div className="starleaf-container">
      <div className="sl-top-container">
        <h2 id="sl-title">StarLeaf internship</h2>
        <div>
          Cambridge <span style={{ color: "var(--color-turquoise)" }}>| </span>
          June-September, 2021
        </div>
      </div>
      <div className="sl-txt-img">
        <div className="txt">
          What a summer, and what an experience. Working in the StarLeaf App
          team was fun, engaging and has challenged and reshaped my perspective
          on Software Engineering from university. I gained valuable experience
          in providing real-world, reliable, software solutions to problems in a
          production system, of scales I have not encountered before. This was
          my first time working in a software organisation, which meant I had to
          adapt to a large codebase that people have worked on for many years.
          I've had the pleasure of architecting and building two projects,
          during which I've expanded my skill set, and learned valuable lessons.
        </div>
        <img src="/images/starleaf.jpg" />
      </div>
      <div className="sl-proj">
        <h4>Mac ProblemReporter</h4>
        <div>
          The ProblemReporter is an executable that users receive from the
          support team when they're having issues with the app. It acts like a
          troubleshooter, running a bunch of diagnostics that are both
          system-wide and StarLeaf specific and sends them over to an internal
          server. This was designed to compile and work only for Windows and my
          job was to get it working for macOS, as well as add more
          functionality, when requested. I refactored the code for it in a way
          that would make it as easy as possible to add new diagnostics, which
          makes it very easy to extend and distribute when a serious bug occurs.
        </div>
      </div>
      <div className="sl-proj">
        <h4>Application-Wide Testing Framework</h4>
        <div>
          After finishing the ProblemReporter, I transitioned into working on a
          set of tools that would allow testing of the entire application
          component, which in turn was split into multiple subcomponents. The
          point of this framework was to make it easy to test the client,
          without the cloud (a giant monolyth that proved to be inconvenient for
          testing the app): specify an app-cloud realtime interaction that would
          run in parallel with a set of UI actions, and then perform assertions
          on the UI side. This involved setting up the UI tests and the realtime
          StarLeaf cloud simulator, writing support code for driving the
          app-cloud protocols, as well as providing scripts and documentation
          for setting up the environemt and bringing it all together. The result
          is a framework that allows the App team to easily create and run
          reliable, cloudless tests on the StarLeaf App, something that is now
          used for both new features and protecting against regressions.
        </div>
      </div>
    </div>
  );
};

export default StarLeaf;
